var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login__container" }, [
    _vm._m(0),
    !_vm.isSignIn
      ? _c("div", { staticClass: "login__form" }, [
          _c("div", { staticClass: "login__container__text" }, [
            _c("p", { staticStyle: { "font-size": "22px" } }, [
              _vm._v(_vm._s(_vm.$t("login.signIn")))
            ])
          ]),
          _vm.genericLogin
            ? _c(
                "div",
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.handleSubmitGeneric($event)
                        }
                      }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-white",
                          attrs: {
                            id: "input-group-1",
                            label: this.$i18n.t("login.username"),
                            "label-for": "input-1"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "input-1",
                              type: "text",
                              placeholder: this.$i18n.t("login.enterUsername"),
                              required: ""
                            },
                            model: {
                              value: _vm.form.usuario,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "usuario", $$v)
                              },
                              expression: "form.usuario"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-white",
                          attrs: {
                            id: "input-group-2",
                            label: this.$i18n.t("login.password"),
                            "label-for": "input-2"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              staticClass: "mt-3",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "b-input-group-text",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.visible = !_vm.visible
                                              }
                                            }
                                          },
                                          [
                                            _c("b-icon", {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                icon: _vm.visible
                                                  ? "eye-slash"
                                                  : "eye",
                                                "aria-hidden": "true"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                654380909
                              )
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-2",
                                  type: _vm.visible ? "text" : "password",
                                  placeholder: this.$i18n.t(
                                    "login.enterPassword"
                                  ),
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "login__container__button",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.send")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "mt-3 login__container__button",
                      on: {
                        click: function($event) {
                          _vm.genericLogin = false
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("login.back")) + " ")]
                  )
                ],
                1
              )
            : _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "login__container__button",
                    attrs: { "data-prompt": "select_account consent" },
                    on: { click: _vm.handleClickSignIn }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("login.signInButton")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "mt-3 login__container__button",
                    on: {
                      click: function($event) {
                        _vm.genericLogin = true
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("login.userPass")) + " ")]
                )
              ])
        ])
      : _c("div", [
          _c("div", { staticClass: "login__container__text" }, [
            _c("p", { staticStyle: { "font-size": "22px" } }, [
              _vm._v(_vm._s(_vm.$t("login.logout")))
            ])
          ]),
          _c(
            "button",
            {
              staticClass: "login__container__button",
              on: { click: _vm.handleClickLogOut }
            },
            [_vm._v(" " + _vm._s(_vm.$t("login.logoutButton")) + " ")]
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login__container__logo" }, [
      _c("img", {
        staticStyle: { "max-width": "180px" },
        attrs: {
          src: require("../assets/images/logo__login.svg"),
          alt: "Logo Ingenes"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }