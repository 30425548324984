// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../assets/css/global.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background__login.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.login__container[data-v-26084dc2] {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-repeat: no-repeat;\r\n  background-size: 100% 100%;\r\n  height: 100vh;\n}\n.login__container label[data-v-26084dc2] {\r\n  display: block;\r\n  color: white;\n}\n.login__container__logo img[data-v-26084dc2] {\r\n  width: 320px;\n}\n.login__container__text[data-v-26084dc2] {\r\n  margin-top: 20px;\n}\n.login__container__text p[data-v-26084dc2] {\r\n  font-family: 'futura-heavy';\r\n  font-size: 26px;\r\n  margin: 24px;\r\n  color: white;\n}\n.login__container__button[data-v-26084dc2] {\r\n  border: 0;\r\n  width: 100%;\r\n  padding: 6px 0;\r\n  border-radius: 50px;\r\n  color: #003085;\r\n  font-weight: bold;\n}\n.login__container__button[data-v-26084dc2]:focus {\r\n  outline: none;\n}\n.login__form[data-v-26084dc2] {\r\n  width: 250px;\n}\r\n", ""]);
// Exports
module.exports = exports;
