<template>
  <div class="login__container">
    <div class="login__container__logo">
      <img src="../assets/images/logo__login.svg" style="max-width: 180px" alt="Logo Ingenes" />
    </div>
    <div class="login__form" v-if="!isSignIn">
      <div class="login__container__text">
        <p style="font-size: 22px">{{ $t('login.signIn') }}</p>
      </div>

      <!-- documentatión: https://www.npmjs.com/package/vue-google-oauth2 -->

      <div v-if="genericLogin">
        <b-form @submit.prevent="handleSubmitGeneric">
          <b-form-group
            id="input-group-1"
            :label="this.$i18n.t('login.username')"
            label-for="input-1"
            class="text-white"
          >
            <b-form-input
              id="input-1"
              v-model="form.usuario"
              type="text"
              :placeholder="this.$i18n.t('login.enterUsername')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            :label="this.$i18n.t('login.password')"
            label-for="input-2"
            class="text-white"
          >
            <b-input-group class="mt-3">
              <b-form-input
                id="input-2"
                v-model="form.password"
                :type="visible ? 'text' : 'password'"
                :placeholder="this.$i18n.t('login.enterPassword')"
                required
              ></b-form-input>
              <template #append>
                <b-input-group-text @click="visible = !visible"
                  ><b-icon :icon="visible ? 'eye-slash' : 'eye'" aria-hidden="true" style="cursor:pointer"></b-icon
                ></b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>

          <button type="submit" class="login__container__button">{{ $t('login.send') }}</button>
        </b-form>
        <button class="mt-3 login__container__button" @click="genericLogin = false">
          {{ $t('login.back') }}
        </button>
      </div>
      <div v-else>
        <button class="login__container__button" @click="handleClickSignIn" data-prompt="select_account consent">
          {{ $t('login.signInButton') }}
        </button>
        <button class="mt-3 login__container__button" @click="genericLogin = true">
          {{ $t('login.userPass') }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="login__container__text">
        <p style="font-size: 22px">{{ $t('login.logout') }}</p>
      </div>

      <!-- documentatión: https://www.npmjs.com/package/vue-google-oauth2 -->
      <button class="login__container__button" @click="handleClickLogOut">
        {{ $t('login.logoutButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import jwtencode from 'jwt-encode';
import { API_GOOGLE_AUTH, API_ROOT, API_HUBSPOT } from '../Constants/Api';
import { AlertForm, AlertLoading, ICON } from '../helpers/Alert';
import { ApiRequest } from '../helpers/ApiRequest';
import pictureIngenes from '../assets/images/logo_blue.png';
const secret = 'ingenes';
export default {
  name: 'Login',
  data() {
    return {
      isInit: false,
      isSignIn: false,
      rol: '',
      genericLogin: false,
      form: {
        usuario: '',
        password: '',
      },
      visible: false,
    };
  },
  beforeCreated() {
    this.$store.dispatch('autoLogin') ? this.$router.push({ name: 'Welcome' }) : false;
  },

  created() {
    this.isSignIn = localStorage.getItem('signIn') || false;
    this.$i18n.locale = window.navigator.language.includes('es') ? 'es' : 'en';
  },

  methods: {
    async getSedesAndSucursales () {
      const sedes = await this.getSedes();
      const sucursales = await this.getSucursales();
      
      localStorage.setItem( 'sucursales', JSON.stringify(sucursales) )
      localStorage.setItem( 'sedes', JSON.stringify(sedes) )
    },
     async getSucursales() {
      try {
        let [ branchesUs, branchesMx ] = await Promise.all([
          ApiRequest.get(`${API_HUBSPOT}sucursalesUS`),
          ApiRequest.get(`${API_HUBSPOT}sucursalesMX`)
        ])

        branchesUs = branchesUs.sucursales.map((el) => ({ name: el, country: 'us' }))
        branchesMx = branchesMx.sucursales.map((el) => ({ name: el, country: 'mx' }))

        return [ ...branchesUs, ...branchesMx ]
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async getSedes() {
      try {
        const data = await ApiRequest.get(`${API_HUBSPOT}sedes`);
        let sedesOrigen = data.sedes.find((el) => el.prop_name === 'sede_origen');
        return sedesOrigen.datos;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        const tokenId = googleUser.getAuthResponse().id_token;
        const resGoogleAuth = await ApiRequest.publicPost(API_GOOGLE_AUTH, {}, { 'xg-token': tokenId });

        if (!resGoogleAuth.ok)
          throw {
            err: true,
            status: resGoogleAuth.status,
            statusText: resGoogleAuth.statusText || 'Error en la API',
          };

        let rol = resGoogleAuth.roles[0] ? resGoogleAuth.roles[0].name : 'Acceso Interfaz';
        if (resGoogleAuth.roles[0] && resGoogleAuth.roles[0].configuracion.length) {
          await this.getSedesAndSucursales()

          sessionStorage.setItem('country', resGoogleAuth.pais === 1 ? 'MX' : 'US');
          const token = jwtencode(rol, secret);

          this.$store.dispatch('guardarRol', token);
          this.$store.dispatch('guardarIdRol', resGoogleAuth.roles[0]['_id']);
          this.$store.dispatch('guardarUserGoogle', tokenId);
          this.isSignIn = this.$gAuth.isAuthorized;
          localStorage.setItem('signIn', this.isSignIn);
          localStorage.setItem('version', resGoogleAuth.version);
          localStorage.setItem('userName', resGoogleAuth.userName);
          this.$store.dispatch('userName', resGoogleAuth.userName);
          this.$router.push({ name: 'Welcome' });
        } else {
          return AlertForm(
            this.$i18n.t('permissions.unauthorizedRol'),
            this.$i18n.t('permissions.validateAdmin'),
            ICON.WARNING,
            'center',
            null
          );
        }
      } catch (error) {
        console.error('ERROR INICIANDO SESIÓN CON GOOGLE', {error});
        if (error === false || error.error === 'popup_closed_by_user') return null;
        if (error.status === 401 && error.statusText === 'Usuario Genérico. Realizar Login genérico') {
          AlertForm('No puede Iniciar Sesion', 'Usuario es generico', ICON.WARNING);
          this.genericLogin = true;
        } else {
          AlertForm(`Error Code: ${error.status}`, error.statusText, ICON.ERROR);
        }
        return null;
      }
    },
    async handleSubmitGeneric() {
      try {
        AlertLoading(this.$i18n.t('login.loggingin'));
        let res = await ApiRequest.publicPost(`${API_ROOT}auth/login-externo`, this.form);

        if (res.roles[0] && res.roles[0].configuracion.length) {
          await this.getSedesAndSucursales()

          if (!res.payload.picture) res.payload.picture = pictureIngenes;
          sessionStorage.setItem('country', res.pais === 1 ? 'MX' : 'US');
          const token = jwtencode(res.payload, secret);
          this.$store.dispatch('guardarUserGoogle', token);
          let rol = res.roles[0].name;

          const tokenRol = jwtencode(rol, secret);

          this.$store.dispatch('guardarRol', tokenRol);
          this.$store.dispatch('guardarIdRol', res.roles[0]['_id']);
          localStorage.setItem('signIn', true);
          localStorage.setItem('version', res.version);
          AlertForm(res.msg, '', ICON.SUCCESS);
          /* se guarda en store el username */
          localStorage.setItem('userName', res.userName);
          this.$store.dispatch('userName', res.userName);
          this.$router.push({ name: 'Welcome' });
        }
      } catch (err) {
        console.log('ERR Usuario Generico', err, err.response);
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR, 'center');
      }
    },
    async handleClickLogOut() {
      try {
        const response = await this.$gAuth.signOut();
        if (response) {
          this.$store.dispatch('logout');
          this.isSignIn = false;
        }
      } catch (error) {
        console.error('ERR Logout', error, error.error);
        return null;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/css/global.css';

.login__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/images/background__login.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.login__container label {
  display: block;
  color: white;
}

.login__container__logo img {
  width: 320px;
}

.login__container__text {
  margin-top: 20px;
}

.login__container__text p {
  font-family: 'futura-heavy';
  font-size: 26px;
  margin: 24px;
  color: white;
}

.login__container__button {
  border: 0;
  width: 100%;
  padding: 6px 0;
  border-radius: 50px;
  color: #003085;
  font-weight: bold;
}

.login__container__button:focus {
  outline: none;
}
.login__form {
  width: 250px;
}
</style>
